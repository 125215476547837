<template>
    <div class="row flex-column justify-content-center align-items-center flex-grow-1 g-0">
        <div class="col-12 col-lg-2 mb-3 mt-5">
            <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                <img class="w-50" :src="require('@/assets/util/images/no_data.svg')" alt="">
            </div>
        </div>
        <div class="col-6 d-flex flex-column align-items-center justify-content-center">
            <h1 class="fs-5 text-neutral-400 text-center">No data found.</h1>
        </div>
    </div>
</template>
