<template>
    <div class="d-flex justify-content-center">
        <div class="loader mt-4 mb-4"></div>
    </div>
</template>
<style>
.loader {
    border: 6px solid #AC53F2;
    border-radius: 50%;
    border-top: 6px solid #171718;
    width: 60px;
    height: 60px;
    -webkit-animation: loadingspin 2s linear infinite;
    /* Safari */
    animation: loadingspin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes loadingspin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loadingspin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
