<template>
  <div class="modal fade" id="editSocialLinkModal">
    <div class="modal-dialog text-neutral-25">
      <div class="modal-content bg-neutral-800">
        <div class="modal-header justify-content-between">
          <h1
            class="modal-title fs-5 text-20 font-weight-600"
            id="editSocialLinkModalLabel"
          >
            Edit Social Links
          </h1>
          <button
            type="button"
            class="bg-neutral-800 border-0"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa-solid fa-times text-neutral-25"></i>
          </button>
        </div>
        <div class="modal-body text-neutral-25">
          <div class="d-flex justify-content-start align-items-center mb-3">
            <p>Manage all your social links here</p>
          </div>
          <form id="updateSocialLinksForm" @submit.prevent="saveSocialLinks" novalidate>
            <template v-for="social in socials" :key="social.id">
              <ClickableFields
                :id="social.id"
                :social_id="social.social_id"
                :link="social.link"
                @update-field="updateSocialField"
                :removeSocialLink="removeSocialLink"
              />
            </template>

            <div class="server_side_errors"></div>
            <div class="modal-footer justify-content-between px-0">
              <button
                type="button"
                class="btn bg-secondary border-0 text-white"
              @click="insertSocialLink"
              >
              <i class="fa-solid fa-plus"></i>
                Add More
              </button>
              <button
                type="submit"
                :disabled="btnLoading"
                :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary']"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closeModal, formValidation, serverSideErrorsHtml } from "@/assets/js/utils";
import ClickableFields from "@/components/Host/Profile/ClickableFields.vue";
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
export default {
  components: {
    ClickableFields,
  },
  props: {
    fetchUserDetails: Function,
    social_links: Array,
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const btnLoading = ref(false);
    // let maxId = props.social_links.reduce((max, current) => {
    //   return current.id > max ? current.id : max;
    // }, -Infinity);
    const socials = ref(props?.social_links?.length ? props.social_links : [{ id: 1, social_id: "", link: "" }]);

    onMounted(async () => {
      await store.dispatch("hostProfile/fetchAllSocialList");
    });

    const insertSocialLink = () => {
      const lastSocial = socials.value[socials.value.length - 1];
      const newId = lastSocial ? lastSocial.id + 1 : 1;
      socials.value.push({ id: newId, social_id: "", link: "" });
    };

    const updateSocialField = ({ id, field, value }) => {
      const social = socials.value.find((social) => social.id === id);
      if (social) {
        social[field] = value;
      }
    };

    const removeSocialLink = (id) => {
      document.getElementById("socialLink_" + id).remove();
      const index = socials.value.findIndex((social) => social.id == id);
      if (index !== -1) {
        socials.value.splice(index, 1);
      }
    };

    const saveSocialLinks = async () => {
        btnLoading.value = true;
      const isValid = formValidation(document.getElementById("updateSocialLinksForm"));
      if (isValid) {
        const allSocials = socials.value.filter((social) => social?.link != "");
        const body = {
          social_links: allSocials,
        };
        const response = await store.dispatch("user/updateSocialLinks", body);
        if (response.data.status) {
          btnLoading.value = false;
          closeModal("editSocialLinkModal");
          toast.success(response.data.msg);
          props.fetchUserDetails();
        } else {
          btnLoading.value = false;
          if (response.status == 422) {
            serverSideErrorsHtml(response, "editSocialLinkModal");
          } else {
            toast.error(response.data.msg);
          }
        }
      } else {
        btnLoading.value = false;
      }
    };

    return {
      btnLoading,
      socials,
      insertSocialLink,
      updateSocialField,
      saveSocialLinks,
      removeSocialLink,
    };
  },
};
</script>
<style scoped></style>
