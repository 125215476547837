import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import config from '@/config/config';

// /* Sweeet Alert */
import VueSweetAlert from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// /* Select2 */
import Select2 from 'vue3-select2-component';

// /* Bootstrap */
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css'; // Import FontAwesome CSS

// /* Datatables */
// import DataTable from 'datatables.net-vue3';
// import DataTablesCore from 'datatables.net';

// /* Calendar */
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

import 'bootstrap';
import './assets/js/script.js'
import './assets/css/style.css';

import { reactive } from 'vue';
import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";

const toastOptions = {
    position: "bottom-left",
    timeout: 1994,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 2,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
    transition: "Vue-Toastification__fade",
};


import User from './components/Helpers/User.js';
import AppStorage from './components/Helpers/AppStorege.js';
window.User = User;


const app = createApp(App);

// /* Vuex */
app.use(store);

// /* Datatables */
// app.component('DataTable', DataTable);
// DataTable.use(DataTablesCore);

// /* Calendar */
app.use(setupCalendar, {})
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);
app.component('VueSelect2', Select2);

// // Create a reactive object to store global variables
const globalVariables = reactive({
    baseURL: config.API_ENDPOINT
});
app.provide('globalVariables', globalVariables);
app.provide('LoginUser', User);
app.provide('AppStorage', AppStorage);
app.use(Toast, toastOptions);
app.provide('router', router);
app.use(router);
app.use(VueTelInput, {
    defaultCountry: 'us'
});
app.use(VueSweetAlert, {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
});
app.mount('#app');
