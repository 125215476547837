<template>
    <h3 class="auth_page_titlte text-neutral-100">Reset Password</h3>
    <p class="text-neutral-200 mb-3">Please provide the necessary information to reset your password</p>

    <form class="w-100" @submit.prevent="sendOtp">
        <div v-if="errorMessages.length > 0"
            :class="['alert', 'alert-danger', 'd-flex', 'align-items-center', 'alert-dismissible', {
                'fade': errorMessages
                    .length > 0,
                'show': errorMessages.length > 0
            }]"
            role="alert">
            <i class="fa-solid fa-triangle-exclamation me-2"></i>
            <div>
                <span v-html="errorMessages.join('</br> ')"></span>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" placeholder="Email" v-model="form.email" required>
            <label for="email">Email <span class="text-danger">*</span></label>
        </div>
        <button type="submit" :disabled="btnLoading" :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary w-100']">Send OTP</button>
    </form>
    <p class="text_middle_line mt-3 mb-3">Remember password?</p>
    <router-link class="btn w-100 text-neutral-25 border-primary" :to="{ name: 'login' }">Login</router-link>
</template>

<script>
    import axios from 'axios';
    import { inject, ref } from 'vue';
    import { useToast } from 'vue-toastification';
    import { useRouter } from 'vue-router';
    import eventBus from '../../Helpers/EventBus.js';

    export default {
        setup() {
            const router = useRouter();
            const globalVariables = inject('globalVariables');
            const toast = useToast();
            const form = ref({
                email: null
            });
            const errorMessages = ref([]);
            const btnLoading = ref(false);

            const sendOtp = async () => {
                btnLoading.value = true;
                axios.post(globalVariables.baseURL + '/api/auth/sendOtp', form.value)
                .then(res => {
                    router.push({
                        name: 'verification'
                    });
                    eventBus.email = res.data.email;
                    toast.success('OTP successfully sent to your email!');
                    btnLoading.value = false;
                })
                .catch(error => {
                    if (error.response && error.response.status === 422) {
                        const errors = error.response.data.errors;
                        errorMessages.value = Object.values(errors).flat();
                    } else if (error.response && error.response.status === 401) {
                        const errors = error.response.data;
                        errorMessages.value = Object.values(errors).flat();
                    } else {
                        console.error('Error:', error);
                        errorMessages.value = ['An unexpected error occurred'];
                    }
                    btnLoading.value = false;
                });
            };

            return {
                router,
                globalVariables,
                toast,
                form,
                sendOtp,
                errorMessages,
                btnLoading
            };
        }
    };
    </script>
