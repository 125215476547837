<template>
    <template v-if="upcomingEventsLoading">
        <LoadingComponent />
    </template>
    <template v-else>
        <div class="row">
            <div class="col-sm-12 col-lg-12 col-xl-9">
                <div class="container p-0">
                    <div class="text-neutral-25 row ">
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 pb-3 ">
                            <div class="bg-neutral-800 ps-3 pt-3 pe-3 rounded dashboard-card">
                                <div class="d-flex gap-2">
                                    <div class="card-icon bg-neutral-900 ps-3 pe-3 pt-2 rounded "><i
                                            class="fa fa-calendar text-secondary"></i></div>
                                    <div>
                                        <h6 class="">EVENTS</h6>
                                        <p class="text-neutral-400 text-3">All time</p>
                                    </div>
                                </div>
                                <h1 class="pt-2 text-center text-28">{{ dashboardData?.events }}</h1>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 pb-3">
                            <div class="bg-neutral-800 ps-3 pt-3 pe-3 rounded dashboard-card">
                                <div class="d-flex gap-2">
                                    <div class="card-icon bg-neutral-900 ps-3 pe-3 pt-2 rounded "><i
                                            class="fas fa-user text-secondary"></i></div>
                                    <div>
                                        <h6>USERS</h6>
                                        <p class="text-neutral-400 text-3">All time</p>
                                    </div>
                                </div>
                                <h1 class="pt-2 text-center text-28">{{ dashboardData?.users }}</h1>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 pb-3">
                            <div class="bg-neutral-800 ps-3 pt-3 pe-3 rounded dashboard-card">
                                <div class="d-flex gap-2">
                                    <div class="card-icon bg-neutral-900 ps-3 pe-3 pt-2 rounded "><i
                                            class="fas fa-music text-secondary"></i></div>
                                    <div>
                                        <h6>REQUEST</h6>
                                        <p class="text-neutral-400 text-3">All time</p>
                                    </div>
                                </div>
                                <h1 class="pt-2 text-center text-28">{{ dashboardData?.requests }}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pb-3">
                    <VCalendar :is-dark="isDark" title-position="left" expanded borderless :attributes='attrs'
                        color="blue" :masks="{ weekdays: 'WWW' }" @dayclick="dayclickHandler" />
                </div>
            </div>
            <div class=" col-sm-12 col-lg-12 col-xl-3">
                <template v-if="!showShcedule">
                    <h5 class="text-neutral-25 text-20">UPCOMING EVENTS</h5>
                    <div class="container p-0 pt-3">
                        <div class="row">
                            <template v-if="events?.length">
                                <router-link  v-for="(event, index) in events.slice(0, 3)" :key="index"
                                   :to="{ name: 'host.event.details', params: { eventId: event.id } }"
                                    class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 pb-3">
                                    <EventCard :event="event" />
                                </router-link>
                                <div class="col-12">                               
                                    <router-link class="text-white w-100" :to="{ name: 'host.event.index' }">
                                      <button  class="w-100  btn-primary text-neutral-25 new-event-button">
                                        See More
                                      </button>
                                    </router-link>
                                </div>
                            </template>
                            <template v-else>
                                <div>
                                    <div class="d-flex flex-column align-items-center">
                                        <div class="text-neutral-25 w-100 d-flex justify-content-center "><i
                                                class="fa-solid fa-rectangle-xmark no-event-icon "></i></div>
                                        <p class="mt-4 text-neutral-300 text-center">You do not have any event</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="d-flex align-items-center justify-content-between gap-3">
                        <div class="d-flex align-items-center gap-3">
                            <button class="border-0 bg-neutral-900 text-neutral-25" @click="backButtonClickHandler"><i
                                    class="fa-solid fa-arrow-left"></i></button>
                            <h5 class="text-neutral-25 text-20 m-0">SCHEDULE</h5>
                        </div>
                        <button @click="openModal('eventCreateModal')" class="btn btn-primary"><i
                                class="fa-solid fa-plus"></i></button>
                    </div>
                    <div class="container p-0 pt-3">
                        <div class="row">
                            <template v-if="events?.length">
                                <router-link :to="{ name: 'host.event.details', params: { eventId: event.id } }"
                                    v-for="(event, index) in events" :key="index"
                                    class=" col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 pb-3">
                                    <EventCard :event="event" />
                                </router-link>
                            </template>
                            <template v-if="!events?.length">
                                <div class="d-flex flex-column align-items-center">
                                    <div class="text-neutral-25 w-100 d-flex justify-content-center "><i
                                            class="fa-solid fa-rectangle-xmark no-event-icon "></i></div>
                                    <p class="mt-4 text-neutral-300 text-center">You do not have any event</p>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <EventCreateForm />
    </template>
</template>

<script>
import { openModal } from '@/assets/js/utils';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import EventCreateForm from '@/components/Host/Event/EventCreateForm';
import EventCard from '@/components/Shared/EventCard.vue';
import LoadingComponent from '@/components/Shared/LoadingComponent.vue';

export default {
    components: {
        EventCreateForm,
        EventCard,
        LoadingComponent,
    },
    setup() {
        const upcomingEventsLoading = ref(true);
        const store = useStore();
        const isDark = ref(true);
        const showShcedule = ref(false);
        const dashboardData = ref({});
        const attrs = ref([
            {
                key: 'today',
                highlight: true,
                dates: new Date(),
            },
        ]);
        const events = ref([]);

        onMounted(async () => {
            await fetchHostUpcomingEvents();
            await store.dispatch('stripe/activateStripeAccount');
        })

        const fetchEvents = async (date) => {
            try {
                await store.dispatch('event/fetchEvents', { date: date });
                console.log(store.getters['event/allEvents'])
                events.value = store.getters['event/allEvents'];
            } catch (error) {
                console.error(error);
            }
        };

        const fetchHostUpcomingEvents = async () => {
            try {
                upcomingEventsLoading.value = true;
                await store.dispatch('event/fetchHostUpcomingEvents', store.getters['user/getUserId']);
                const fetchedEvents = store.getters['event/getHostUpcomingEvents'];
                events.value = fetchedEvents;

                await store.dispatch('dashboard/fetchHostDashboardData');
                dashboardData.value = store.getters['dashboard/getHostDashboardData'];
                upcomingEventsLoading.value = false;
            } catch (error) {
                console.error(error);
            }
        };

        const dayclickHandler = async (date) => {
            showShcedule.value = true;
            await fetchEvents(date.id);
        }

        const backButtonClickHandler = async () => {
            await fetchHostUpcomingEvents();
            showShcedule.value = false;
        }

        return {
            isDark,
            attrs,
            dayclickHandler,
            events,
            backButtonClickHandler,
            showShcedule,
            openModal,
            dashboardData,
            upcomingEventsLoading
        }
    }
}
</script>

<style>
.no-event-icon {
    font-size: 100px;
    color: #333334;
}

.vc-container {
    background: #242425 !important;
    border-radius: 12px;
    overflow: hidden;
}

.vc-weeks {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.vc-week:last-child {
    display: none;
}

.vc-weekday {
    min-height: 52px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vc-day {
    background: #333334;
    margin: 1px;
    min-height: 52px !important;
}

.vc-header .vc-arrow,
.vc-nav-arrow,
.vc-header .vc-title,
.vc-nav-item,
.vc-nav-title {
    background: none;
}

.vc-nav-item {
    color: #e2e8f0;
}

.card-icon {
    font-size: 24px;
}

.dashboard-card {
    border: 1px solid #333334;
}

.dashboard-card:hover {
    border: 1px solid #AC53F2;
}

@media (max-width: 991px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 100% !important;
    }
}
</style>
