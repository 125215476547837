<template>
    <div class="row g-0 vh-100 auth_container">
        <div class="d-none d-lg-block col-8 h-100 position-relative">
            <img class="w-100 h-100 object-fit-cover" :src="require('@/assets/util/images/auth.png')" alt="">
            <div class="position-absolute auth_text_container">
                <h1 class="auth_site_title">CROWDQ</h1>
                <p class="auth_moto">Where event creators and attendees connect to build unforgettable experiences.</p>
            </div>
        </div>
        <div class="col-12 col-lg-4 h-100 p-4 p-lg-5">
            <p class="auth_welcome_message text-neutral-200">Hi, welcome to</p>
            <h1 class="auth_site_title">CROWDQ</h1>
            <router-view></router-view>
        </div>
    </div>
</template>

<style>
.auth_container {
    background: #171718;
}

.auth_text_container {
    bottom: 60px;
    left: 60px;
}

.auth_site_title {
    font-size: 56px;
    background-image: linear-gradient(94deg, #9C4384 0%, #C95A77 33.14%, #DA8168 68.14%, #CB9B62 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.auth_moto {
    color: #fff;
    font-size: 18px;
}

.text_middle_line {
    overflow: hidden;
    text-align: center;
    color: #D1D1D1;
}

.text_middle_line::before,
.text_middle_line::after {
    background-color: #D1D1D1;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.text_middle_line::before {
    right: 0.5em;
    margin-left: -50%;
}

.text_middle_line::after {
    left: 0.5em;
    margin-right: -50%;
}

@media screen and (max-width: 560px) {
    .auth_site_title {
        font-size: 30px;
    }
}
</style>
