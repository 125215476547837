<template>
  <template v-if="!pageLoading">
    <div class="position-relative">
      <img
        class="cover-image position-relative rounded"
        :src="user.cover_photo_path || require('@/assets/util/images/cover.jpg')"
        alt=""
      />
      <img
        class="profile-image rounded-circle position-absolute bottom-0 start-50 object-fit-cover"
        :src="user.profile_image_path || require('@/assets/util/images/avatar.png')"
        alt=""
      />
    </div>
    <div class="profile-short-info-card mt-3">
      <div></div>
      <div class="profile__short__info--right">
        <span class="dropdown">
          <router-link :to="{ name: 'attendee.profile.edit' }" class="text-neutral-25">
            <button
              class="rounded share-qr bg-neutral-800 font-weight-bold ms-2 text-neutral-25"
            >
              <i class="fa-solid fa-pen"></i>
            </button>
          </router-link>
        </span>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center user_info mb-4">
      <h3 class="text-neutral-25 text-28 font-weight-600">{{ user.display_name }}</h3>
      <p class="text-neutral-300 text-center px-1 px-lg-5">{{ user.bio }}</p>
    </div>

    <!-- Social Links Section -->
    <section class="bg-neutral-800 text-neutral-25 rounded px-3 py-3 row">
      <div class="position-relative d-flex justify-content-center">
        <h3 class="text-neutral-25 text-20 mb-4">Social Links</h3>
        <button
          type="button"
          @click="openModal('editSocialLinkModal')"
          class="btn bg-neutral-900 text-neutral-25 position-absolute end-0 top-0"
        >
          <i class="fa-solid fa-pencil"></i>
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="d-flex justify-content-center gap-3 col-12 col-md-10 flex-wrap">
          <template v-if="user?.social_links?.length">
            <a
              target="_blank"
              class="d-flex align-items-center gap-2 bg-neutral-900 px-3 rounded-pill border py-1 text-neutral-25"
              :href="social_link?.link"
              v-for="social_link in user?.social_links"
              :key="social_link.id"
            >
              <img
                class="profile_page_social_link_logo"
                :src="social_link?.logo"
                :alt="social_link?.name"
              />
              {{ social_link?.name }}
            </a>
          </template>
          <p v-if="!user?.social_links?.length">
            Please add some social links to show here.
          </p>
        </div>
      </div>
    </section>
    <EditSocialLink
    :social_links="user?.social_links"
    :fetchUserDetails="fetchUserDetails"
  />

    <div class="mt-5">
      <div class="d-flex justify-content-between">
        <h3 class="text-neutral-25 text-20">Joined Events</h3>
        <!-- <button class=" see-all-btn btn-primary ps-4 pe-4 rounded">SEE ALL <i
                    class="fa-solid fa-arrow-right ms-2"></i></button> -->
      </div>
      <template v-if="enrolledEvents?.length">
        <div class="mt-3 cards row">
          <router-link
            :to="{ name: 'attendee.event.details', params: { eventId: event.id } }"
            v-for="(event, index) in enrolledEvents"
            :key="index"
            class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 pb-3"
          >
            <EventCard :event="event" />
          </router-link>
        </div>
      </template>
      <template v-if="!enrolledEvents?.length">
        <NoData />
      </template>
    </div>
  </template>
  <template v-else>
    <LoadingComponent />
  </template>

</template>
<script>
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import EventCard from "@/components/Shared/EventCard.vue";
import NoData from "@/components/Shared/NoData.vue";
import LoadingComponent from "@/components/Shared/LoadingComponent.vue";
import { openModal } from "@/assets/js/utils";
import EditSocialLink from "@/components/Host/Profile/EditSocialLink.vue";

export default {
  components: {
    EventCard,
    NoData,
    LoadingComponent,
    EditSocialLink
  },
  setup() {
    const store = useStore();
    const user = ref({});
    let userProfileCode = null;
    let userQrCode = null;
    const toast = useToast();
    const enrolledEvents = ref([]);
    const pageLoading = ref(true);

    const profileCode = () => {
      navigator.clipboard.writeText(userProfileCode).then(() => {
        toast.success("Copy success");
      });
    };
    const copyQrCode = () => {
      navigator.clipboard.writeText(userQrCode).then(() => {
        toast.success("Copy success");
      });
    };
    const downloadQrCode = () => {
      const imageUrl = "../../assets/util/images/logo.png";
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "myqr.png";
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    };

    const fetchAttendeeEnrolledEvents = async () => {
      try {
        await store.dispatch(
          "attendeeEvent/fetchAttendeeEnrolledEvents",
          store.getters["user/getUserId"]
        );
        enrolledEvents.value = store.getters["attendeeEvent/getAttendeeEnrolledEvents"];
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserDetails = async () => {
      try {
        pageLoading.value = true;
        await store.dispatch("hostProfile/fetchUserDetails");
        user.value = store.getters["hostProfile/getProfileInfoData"];
        pageLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {
      await fetchAttendeeEnrolledEvents();
      await fetchUserDetails();
      const profileCodeElement = document.querySelector(".profile-code");
      if (profileCodeElement) {
        userProfileCode = profileCodeElement.innerText;
      }
      const copyQrCodeElement = "my-profile-url";
      if (copyQrCodeElement) {
        userQrCode = copyQrCodeElement;
      }
    });
    return {
      profileCode,
      copyQrCode,
      downloadQrCode,
      enrolledEvents,
      user,
      pageLoading,
      fetchUserDetails,
      openModal,
    };
  },
};
</script>
<style scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-short-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cover-image {
  height: 280px;
  width: 100%;
  object-fit: cover;
}

.profile-image {
  width: 180px;
  height: 180px;
  border: 5px solid #242425;
  transform: translate(-50%, 50%);
}

.tips {
  padding: 5px 15px 5px 10px;
}

.tips-icon {
  padding: 7px 12px 7px 12px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-self: center;
}

.star {
  color: rgba(243, 137, 0, 0.933);
}

.tips-icon i {
  font-size: 18px;
}

.share-qr {
  padding: 10px 15px 10px 15px;
  border: none;
}

.user-name {
  margin-top: 250px;
}

.card {
  border: 1px solid #484848 !important;
}

.card-date {
  font-weight: bold;
  border: none;
}

.qr-code {
  width: 244px;
  height: 244px;
}

.border-separator {
  width: 1px;
  height: 50%;
  background-color: #747475;
}

.modal-header {
  border-bottom: none !important;
}

.dropdown-ul {
  right: 38px !important;
}

.dropdown-menu {
  --bs-dropdown-link-hover-bg: none !important;
  --bs-dropdown-link-active-bg: none !important;
}

@media (max-width: 992px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .user-name {
    margin-top: 180px;
  }
}

@media (max-width: 1200px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .user-name {
    margin-top: 220px;
  }

  .cover-image {
    height: 180px;
  }
}
</style>
